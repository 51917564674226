import React from 'react'
import './Intro.scss'
import { init } from 'ityped'
import {useEffect,useRef} from "react"


export default function Intro() {

    const textRef = useRef();

    useEffect(()=>{
        init(textRef.current,{
            showCursor:true,
            backDelay:1500,
            backSpeed:60,

            strings:["Developers","Designer"],
        });
    },[])
    return (
        <div className='intro' id='intro'>
            <div className="left">
                <div className="imgContainer">
                    <img src="https://dl.dropboxusercontent.com//s/ikiiphpzlqt6zfk/man.png?dl=0" alt=""/>
                </div>
            </div>
            <div className="right">
                <div className="wrapper">
                   <h2>Hi There, Welcome To</h2>
                   <h1>DELT</h1> 
                   <h3>Freelance <span ref={textRef}></span></h3>
                </div>
                <a href='#portfolio'>
                    <img src="https://dl.dropboxusercontent.com//s/lcf41qv2v29onr0/down.png?dl=0" alt=""/>
                </a>
            </div>
          
        </div>
    )
}

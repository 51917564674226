import {useState} from 'react'
import React from 'react'
import "./Contact.scss"


export default function Contact() {

    const[message,setMessage] = useState(false)

    const handleSubmit =(e)=>{
        e.preventDefault();
        
        setMessage(true)
    }
    return (
        <div className='contact' id='contact' >
            <div className="left">
                <img src="https://dl.dropboxusercontent.com//s/7dyoy19sc5z3wbz/shake.png?dl=0" alt=""/>
            </div>
        <div className="right">
            <h2>Contact</h2>
            <form onSubmit={handleSubmit}>
                <input type="text"placeholder="Email"/>
                <textarea placeholder="message"></textarea>
                <button type='submit'>Send</button>
                {message && <span>Thanks, We will get back to you shortly</span>}
            </form>
        </div>
        </div>
    )
}

import './Topbar.scss'


export default function Topbar({ menuOpen, setMenuOpen }) {
    return (
      <div className={"topbar " + (menuOpen && "active")}>
        <div className="wrapper">
          <div className="left">
            <a href="#intro" className="logo">
              DELT.
            </a>
            <div className="itemContainer">
             <p>
               <span class="flag-icon flag-icon-gr" id="flag"></span>
              <span>+968 78371329</span>
            </p>
            </div>
            <div className="itemContainer">
              
              <span>info@deltsolutions.com</span>
            </div>
          </div>
          <div className="right">
            <div className="hamburger" onClick={()=>setMenuOpen(!menuOpen)}>
              <span className="line1"></span>
              <span className="line2"></span>
              <span className="line3"></span>
            </div>
          </div>
        </div>
      </div>
    );
  }
  